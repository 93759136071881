import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components
import classNames from "classnames";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-kit-react.js";

// import homePageStyles from "assets/jss/material-kit-react/views/homePage.js";

const styles = {
  cardTitle,
  pp: {
    paddingTop: "3vh",
    margin: "auto",
    backgroundColor: "rgb(255, 255, 255)",
    width: "100vh",
    justify: "center"
  }
};

const useStyles = makeStyles(styles);

export default function PrivacyPolicyPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <Header
        absolute
        fixed
        color="dark"
        brand="Beebop Café TV"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      {/* <div style={{ backgroundColor: "rgb(255, 255, 255)", height: "3vh", overflowX: "hidden", width: "100vh" }}>HERE</div> */}
      <div className={classNames(classes.main, classes.mainRaised, classes.pp)}>
        <div className={classes.container}>
          <Card>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <h1>PRIVACY NOTICE</h1>
                  <h6>Last updated May 22, 2022</h6>
                  <p>
                    Thank you for choosing to be part of our community at
                    BEEBOPCAFE.TV LTD, doing business as BeebopCafe TV (“BeebopCafe
                    TV”, “we”, “us”, or “our”). We are committed to protecting
                    your personal information and your right to privacy. If you
                    have any questions or concerns about our notice, or our
                    practices with regards to your personal information, please
                    contact us at{" "}
                    <a href="mailto:hello@beebopcafe.tv">hello@beebopcafe.tv</a>
                    .
                  </p>
                  <p>
                    When you visit our website{" "}
                    <a href="https://beebopcafe.tv">https://beebopcafe.tv</a>,
                    and use our services, you trust us with your personal
                    information. We take your privacy very seriously. In this
                    privacy notice, we seek to explain to you in the clearest
                    way possible what information we collect, how we use it and
                    what rights you have in relation to it. We hope you take
                    some time to read through it carefully, as it is important.
                    If there are any terms in this privacy notice that you do
                    not agree with, please discontinue use of our Sites and our
                    services.
                  </p>
                  <p>
                    This privacy notice applies to all information collected
                    through our website (such as{" "}
                    <a href="https://beebopcafe.tv">https://beebopcafe.tv</a>
                    ), and/or any related services, sales, marketing or events
                    (we refer to them collectively in this privacy notice as
                    {'"'}Services{'"'}).
                  </p>
                  <p>
                    Please read this privacy notice carefully as it will help
                    you make informed decisions about sharing your personal
                    information with us.
                  </p>
                  <h3>TABLE OF CONTENTS</h3>
                  <ol>
                    <li>
                      <a href="#toc_1">WHAT INFORMATION DO WE COLLECT?</a>
                    </li>
                    <li>
                      <a href="#toc_2">HOW DO WE USE YOUR INFORMATION?</a>
                    </li>
                    <li>
                      <a href="#toc_3">
                        WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                      </a>
                    </li>
                    <li>
                      <a href="#toc_4">
                        DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                      </a>
                    </li>
                    <li>
                      <a href="#toc_5">DO WE USE GOOGLE MAPS?</a>
                    </li>
                    <li>
                      <a href="#toc_6">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
                    </li>
                    <li>
                      <a href="#toc_7">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                    </li>
                    <li>
                      <a href="#toc_8">
                        DO WE COLLECT INFORMATION FROM MINORS?
                      </a>
                    </li>
                    <li>
                      <a href="#toc_9">WHAT ARE YOUR PRIVACY RIGHTS?</a>
                    </li>
                    <li>
                      <a href="#toc_10">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                    </li>
                    <li>
                      <a href="#toc_11">
                        DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                      </a>
                    </li>
                    <li>
                      <a href="#toc_12">DO WE MAKE UPDATES TO THIS POLICY?</a>
                    </li>
                    <li>
                      <a href="#toc_13">
                        HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                      </a>
                    </li>
                  </ol>
                  <h4>
                    <span id="toc_1"></span>1. WHAT INFORMATION DO WE COLLECT?
                  </h4>
                  <h5>Information automatically collected</h5>
                  <p>
                    <strong>In Short:</strong> Some information — such as IP
                    address and/or browser and device characteristics — is
                    collected automatically when you visit our Services.
                  </p>
                  <p>
                    We automatically collect certain information when you visit,
                    use or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes. Like many businesses, we
                    also collect information through cookies and similar
                    technologies.
                  </p>
                  <h4>
                    <span id="toc_2"></span>2. HOW DO WE USE YOUR INFORMATION?
                  </h4>
                  <p>
                    <strong>In Short:</strong> We process your information for
                    purposes based on legitimate business interests, the
                    fulfillment of our contract with you, compliance with our
                    legal obligations, and/or your consent.
                  </p>
                  <p>
                    We use personal information collected via our Services for a
                    variety of business purposes described below. We process
                    your personal information for these purposes in reliance on
                    our legitimate business interests, in order to enter into or
                    perform a contract with you, with your consent, and/or for
                    compliance with our legal obligations. We indicate the
                    specific processing grounds we rely on next to each purpose
                    listed below.
                  </p>
                  <p>
                    We use the information we collect or receive:
                    <ul>
                      <li>
                        To facilitate account creation and logon process. If you
                        choose to link your account with us to a third party
                        account (such as your Google or Facebook account), we
                        use the information you allowed us to collect from those
                        third parties to facilitate account creation and logon
                        process for the performance of the contract.
                      </li>
                      <li>
                        To send administrative information to you. We may use
                        your personal information to send you product, service
                        and new feature information and/or information about
                        changes to our terms, conditions, and policies.
                      </li>
                      <li>
                        Fulfill and manage your orders. We may use your
                        information to fulfill and manage your orders, payments,
                        returns, and exchanges made through the Services.
                      </li>
                      <li>
                        To protect our Services. We may use your information as
                        part of our efforts to keep our Services safe and secure
                        (for example, for fraud monitoring and prevention).
                      </li>
                      <li>
                        To enforce our terms, conditions and policies for
                        Business Purposes, Legal Reasons and Contractual.
                      </li>
                      <li>
                        To respond to legal requests and prevent harm. If we
                        receive a subpoena or other legal request, we may need
                        to inspect the data we hold to determine how to respond.
                      </li>
                      <li>
                        To manage user accounts. We may use your information for
                        the purposes of managing our account and keeping it in
                        working order.
                      </li>
                      <li>
                        To deliver services to the user. We may use your
                        information to provide you with the requested service.
                      </li>
                      <li>
                        To respond to user inquiries/offer support to users. We
                        may use your information to respond to your inquiries
                        and solve any potential issues you might have with the
                        use of our Services.
                      </li>
                    </ul>
                    <strong>For other Business Purposes.</strong> We may use
                    your information for other Business Purposes, such as data
                    analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns and to evaluate
                    and improve our Services, products, marketing and your
                    experience. We may use and store this information in
                    aggregated and anonymized form so that it is not associated
                    with individual end users and does not include personal
                    information. We will not use identifiable personal
                    information without your consent.
                  </p>
                  <h4>
                    <span id="toc_3"></span>3. WILL YOUR INFORMATION BE SHARED WITH
                    ANYONE?
                  </h4>
                  <p>
                    <strong>In Short:</strong> We only share information with
                    your consent, to comply with laws, to provide you with
                    services, to protect your rights, or to fulfill business
                    obligations. We may process or share data based on the
                    following legal basis:
                    <ul>
                      <li>
                        Consent: We may process your data if you have given us
                        specific consent to use your personal information in a
                        specific purpose.
                      </li>
                      <li>
                        Legitimate Interests: We may process your data when it
                        is reasonably necessary to achieve our legitimate
                        business interests.
                      </li>
                      <li>
                        Performance of a Contract: Where we have entered into a
                        contract with you, we may process your personal
                        information to fulfill the terms of our contract.
                      </li>
                      <li>
                        Legal Obligations: We may disclose your information
                        where we are legally required to do so in order to
                        comply with applicable law, governmental requests, a
                        judicial proceeding, court order, or legal process, such
                        as in response to a court order or a subpoena (including
                        in response to public authorities to meet national
                        security or law enforcement requirements).
                      </li>
                      <li>
                        Vital Interests: We may disclose your information where
                        we believe it is necessary to investigate, prevent, or
                        take action regarding potential violations of our
                        policies, suspected fraud, situations involving
                        potential threats to the safety of any person and
                        illegal activities, or as evidence in litigation in
                        which we are involved. More specifically, we may need to
                        process your data or share your personal information in
                        the following situations:
                      </li>
                      <li>
                        <ul>
                          <li>
                            Vendors, Consultants and Other Third-Party Service
                            Providers. We may share your data with third party
                            vendors, service providers, contractors or agents
                            who perform services for us or on our behalf and
                            require access to such information to do that work.
                            Examples include: payment processing, data analysis,
                            email delivery, hosting services, customer service
                            and marketing efforts. We may allow selected third
                            parties to use tracking technology on the Services,
                            which will enable them to collect data about how you
                            interact with the Services over time. This
                            information may be used to, among other things,
                            analyze and track data, determine the popularity of
                            certain content and better understand online
                            activity. Unless described in this Policy, we do not
                            share, sell, rent or trade any of your information
                            with third parties for their promotional purposes.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Business Transfers. We may share or transfer your
                        information in connection with, or during negotiations
                        of, any merger, sale of company assets, financing, or
                        acquisition of all or a portion of our business to
                        another company.
                      </li>
                      <li>
                        Third-Party Advertisers. We may use third-party
                        advertising companies to serve ads when you visit the
                        Services. These companies may use information about your
                        visits to our Website(s) and other websites that are
                        contained in web cookies and other tracking technologies
                        in order to provide advertisements about goods and
                        services of interest to you.
                      </li>
                    </ul>
                  </p>
                  <h4>
                    <span id="toc_4"></span>4. DO WE USE COOKIES AND OTHER TRACKING
                    TECHNOLOGIES?
                  </h4>
                  <p>
                    <strong>In Short:</strong> We may use cookies and other
                    tracking technologies to collect and store your information.
                  </p>
                  <p>
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Policy.
                  </p>
                  <h4>
                    <span id="toc_5"></span>5. DO WE USE GOOGLE MAPS?
                  </h4>
                  <p>
                    <strong>In Short:</strong> Yes, we use Google Maps for the
                    purpose of providing better service.
                  </p>
                  <p>
                    This website, mobile application, or Facebook application
                    uses Google Maps APIs. You may find the Google Maps APIs
                    Terms of Service here. To better understand Google’s Privacy
                    Policy, please refer to this{" "}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      link
                    </a>
                    .
                  </p>
                  <p>
                    By using our Maps API Implementation, you agree to be bound
                    by Google’s Terms of Service.
                  </p>
                  <h4>
                    <span id="toc_6"></span>6. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </h4>
                  <p>
                    <strong>In Short:</strong> We keep your information for as
                    long as necessary to fulfill the purposes outlined in this
                    privacy notice unless otherwise required by law.
                  </p>
                  <p>
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting or other legal
                    requirements). No purpose in this policy will require us
                    keeping your personal information for longer than 6 months
                    past the termination of the user{"'"}s account.
                  </p>
                  <p>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize it, or, if this is not possible (for example,
                    because your personal information has been stored in backup
                    archives), then we will securely store your personal
                    information and isolate it from any further processing until
                    deletion is possible.
                  </p>
                  <h4>
                    <span id="toc_7"></span>7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </h4>
                  <p>
                    <strong>In Short:</strong> We aim to protect your personal
                    information through a system of organizational and technical
                    security measures.
                  </p>
                  <p>
                    We have implemented appropriate technical and organizational
                    security measures designed to protect the security of any
                    personal information we process. However, please also
                    remember that we cannot guarantee that the internet itself
                    is 100% secure. Although we will do our best to protect your
                    personal information, transmission of personal information
                    to and from our Services is at your own risk. You should
                    only access the services within a secure environment.
                  </p>
                  <h4>
                    <span id="toc_8"></span>8. DO WE COLLECT INFORMATION FROM MINORS?
                  </h4>
                  <p>
                    <strong>In Short:</strong> We do not knowingly collect data
                    from or market to children under 18 years of age.
                  </p>
                  <p>
                    We do not knowingly solicit data from or market to children
                    under 18 years of age. By using the Services, you represent
                    that you are at least 18 or that you are the parent or
                    guardian of such a minor and consent to such minor
                    dependent’s use of the Services. If we learn that personal
                    information from users less than 18 years of age has been
                    collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our
                    records. If you become aware of any data we have collected
                    from children under age 18, please contact us at{" "}
                    <a href="mailto:hello@beebopcafe.tv">hello@beebopcafe.tv</a>
                    .
                  </p>
                  <h4>
                    <span id="toc_9"></span>9. WHAT ARE YOUR PRIVACY RIGHTS?
                  </h4>
                  <p>
                    <strong>In Short:</strong> In some regions, such as the
                    European Economic Area, you have rights that allow you
                    greater access to and control over your personal
                    information. You may review, change, or terminate your
                    account at any time.
                  </p>
                  <p>
                    In some regions (like the European Economic Area), you have
                    certain rights under applicable data protection laws. These
                    may include the right (i) to request access and obtain a
                    copy of your personal information, (ii) to request
                    rectification or erasure; (iii) to restrict the processing
                    of your personal information; and (iv) if applicable, to
                    data portability. In certain circumstances, you may also
                    have the right to object to the processing of your personal
                    information. To make such a request, please use the contact
                    details provided below. We will consider and act upon any
                    request in accordance with applicable data protection laws.
                  </p>
                  <p>
                    If we are relying on your consent to process your personal
                    information, you have the right to withdraw your consent at
                    any time. Please note however that this will not affect the
                    lawfulness of the processing before its withdrawal.
                  </p>
                  <p>
                    If you are resident in the European Economic Area and you
                    believe we are unlawfully processing your personal
                    information, you also have the right to complain to your
                    local data protection supervisory authority. You can find
                    their contact details here:{" "}
                    <a
                      href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                    </a>
                    .
                  </p>
                  <p>
                    If you have questions or comments about your privacy rights,
                    you may email us at{" "}
                    <a href="mailto:hello@beebopcafe.tv">hello@beebopcafe.tv</a>
                    .
                  </p>
                  <h5>Account Information</h5>
                  If you would at any time like to review or change the
                  information in your account or terminate your account, you
                  can:
                  <ul>
                    <li>Contact us using the contact information provided.</li>
                  </ul>
                  <p>
                    Upon your request to terminate your account, we will
                    deactivate or delete your account and information from our
                    active databases. However, some information may be retained
                    in our files to prevent fraud, troubleshoot problems, assist
                    with any investigations, enforce our Terms of Use and/or
                    comply with legal requirements.
                  </p>
                  <p>
                    Cookies and similar technologies: Most Web browsers are set
                    to accept cookies by default. If you prefer, you can usually
                    choose to set your browser to remove cookies and to reject
                    cookies. If you choose to remove cookies or reject cookies,
                    this could affect certain features or services of our
                    Services. To opt-out of interest-based advertising by
                    advertisers on our Services visit
                    http://www.aboutads.info/choices/.
                  </p>
                  <p>
                    Opting out of email marketing: You can unsubscribe from our
                    marketing email list at any time by clicking on the
                    unsubscribe link in the emails that we send or by contacting
                    us using the details provided below. You will then be
                    removed from the marketing email list – however, we will
                    still need to send you service-related emails that are
                    necessary for the administration and use of your account. To
                    otherwise opt-out, you may:
                  </p>
                  <h4>
                    <span id="toc_10"></span>10. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </h4>
                  <p>
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track (“DNT”) feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. No uniform technology
                    standard for recognizing and implementing DNT signals has
                    been finalized. As such, we do not currently respond to DNT
                    browser signals or any other mechanism that automatically
                    communicates your choice not to be tracked online. If a
                    standard for online tracking is adopted that we must follow
                    in the future, we will inform you about that practice in a
                    revised version of this privacy notice.
                  </p>
                  <h4>
                    <span id="toc_11"></span>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
                    PRIVACY RIGHTS?
                  </h4>
                  <p>
                    <strong>In Short:</strong> Yes, if you are a resident of
                    California, you are granted specific rights regarding access
                    to your personal information.
                  </p>
                  <p>
                    California Civil Code Section 1798.83, also known as the
                    “Shine The Light” law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below.
                  </p>
                  <p>
                    If you are under 18 years of age, reside in California, and
                    have a registered account with the Services, you have the
                    right to request removal of unwanted data that you publicly
                    post on the Services. To request removal of such data,
                    please contact us using the contact information provided
                    below, and include the email address associated with your
                    account and a statement that you reside in California. We
                    will make sure the data is not publicly displayed on the
                    Services, but please be aware that the data may not be
                    completely or comprehensively removed from our systems.
                  </p>
                  <h4>
                    <span id="toc_12"></span>12. DO WE MAKE UPDATES TO THIS POLICY?
                  </h4>
                  <p>
                    <strong>In Short:</strong> Yes, we will update this policy
                    as necessary to stay compliant with relevant laws.
                  </p>
                  <p>
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an updated “Revised”
                    date and the updated version will be effective as soon as it
                    is accessible. If we make material changes to this privacy
                    notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a
                    notification. We encourage you to review this privacy notice
                    frequently to be informed of how we are protecting your
                    information.
                  </p>
                  <h4>
                    <span id="toc_13"></span>13. HOW CAN YOU CONTACT US ABOUT THIS
                    POLICY?
                  </h4>
                  <p>
                    If you have questions or comments about this policy, you may
                    email us at{" "}
                    <a href="mailto:hello@beebopcafe.tv">hello@beebopcafe.tv</a>
                    or by post to:
                  </p>
                  <p>
                    BEEBOPCAFE.TV LTD <br></br>
                    20 Bogdan Str.<br></br>
                    Sofia 1505<br></br>
                    Bulgaria
                  </p>
                  <h5>
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </h5>
                  <p>
                    Based on the laws of some countries, you may have the right
                    to request access to the personal information we collect
                    from you, change that information, or delete it in some
                    circumstances. To request to review, update, or delete your
                    personal information, please submit a request form by
                    clicking here. We will respond to your request within 30
                    days.
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
}
