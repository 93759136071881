import React, { useState, useEffect } from "react";
import API from "utils/API";
import clsx from "clsx";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
// import { DevTool } from "react-hook-form-devtools";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Danger from "components/Typography/Danger.js";
import Muted from "components/Typography/Muted.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import { cardTitle } from "assets/jss/material-kit-react.js";
import Icon from "@material-ui/core/Icon";

import {
  TextField,
  Container,
  CssBaseline,
  makeStyles
} from "@material-ui/core";

CheckoutForm.propTypes = {
  intent: PropTypes.string
};

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const { register, errors, handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      phone: ""
    }
  });

  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      alignItems: "center"
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative"
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700]
      }
    },
    fabProgress: {
      color: green[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    textField: {
      marginTop: "1em"
    },
    buttonAlign: {
      textAlign: "center",
      paddingTop: "5px"
    },
    cardTitle
  }));

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [cardError, setCardError] = useState("");
  // const [access, setSuccess] = useState(false);
  const [accessData, setAccessData] = useState("");
  const [paymentId, setPaymentId] = useState("");

  const handlePaymentForm = e => {
    props.visible(e, false);
  };

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success
  });

  useEffect(() => {
    const getAccessTransaction = paymentIntentId => {
      API.get(`/access/transaction/${paymentIntentId}`)
        .then(response => {
          if (response.data.length > 0) {
            setAccessData(response.data[0]);
            window.localStorage.setItem(
              response.data[0].eventId,
              response.data[0].accessCode
            );
          } else {
            setTimeout(() => {
              getAccessTransaction(paymentIntentId);
            }, 2000);
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {});
    };
    if (paymentId !== "") getAccessTransaction(paymentId);
  }, [paymentId]);

  const handleSubmitPayment = async (data, event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const result = await stripe.confirmCardPayment(props.intent, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: data
      },
      receipt_email: data.email
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setCardError(result.error.message);
      setLoading(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        setLoading(false);
        setSuccess(true);
        setPaymentId(result.paymentIntent.id);

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  if (!success) {
    return (
      <>
        <GridContainer>
          <GridItem xs={10} sm={10} md={11}>
            <h4 className={classes.cardTitle}>Payment and User Details</h4>
          </GridItem>
          <GridItem xs={2} sm={2} md={1}>
            <Icon onClick={handlePaymentForm}> clear </Icon>
          </GridItem>
        </GridContainer>

        <Container>
          {/* <DevTool control={control} /> */}
          <CssBaseline />
          <form onSubmit={handleSubmit(handleSubmitPayment)}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Clearfix />
                <CardSection />
                {cardError && <Danger>{cardError}</Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  label="Name"
                  fullWidth
                  variant="standard"
                  type="text"
                  name="name"
                  placeholder="First Last"
                  className={classes.textField}
                  error={!!errors.name}
                  inputRef={register({ required: true })}
                />
                {errors.name && <Danger>Name is required</Danger>}
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <TextField
                  label="Email"
                  fullWidth
                  variant="standard"
                  type="text"
                  name="email"
                  placeholder="you@example.com"
                  className={classes.textField}
                  error={!!errors.email}
                  inputRef={register({ pattern: /^\S+@\S+$/, required: true })}
                />
                {errors.name && <Danger>Valid email is required</Danger>}
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <TextField
                  label="Mobile Phone"
                  fullWidth
                  variant="standard"
                  type="text"
                  name="phone"
                  placeholder="+1234567890"
                  className={classes.textField}
                  error={!!errors.phone}
                  inputRef={register({
                    pattern: /^\+\d+$/,
                    required: true,
                    min: 4,
                    maxLength: 16
                  })}
                />
                {errors.phone && <Danger>Mobile number (+country code)</Danger>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Muted>
                  Please enter your payment details. We use{" "}
                  <strong>Email</strong> and <strong>Mobile Numbers</strong> to
                  deliver your access code. Mobile numbers must start with a
                  country code (e.g. +1). <hr></hr>By buying a ticket you agree
                  with our
                  <a href="/privacy-policy">Terms and Conditions</a>.
                </Muted>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.buttonAlign}>
                <Button
                  variant="contained"
                  color="success"
                  className={buttonClassname}
                  disabled={
                    !stripe ||
                    !!errors.name ||
                    !!errors.phone ||
                    !!errors.email ||
                    loading
                  }
                  type="submit"
                >
                  Buy Ticket
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </GridItem>
            </GridContainer>
          </form>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4 className={classes.cardTitle}>Thank You</h4>
          </GridItem>
          <GridItem xs={2} sm={2} md={2}>
            {!accessData.accessCode && <CircularProgress color="primary" />}
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <h4>
              {!accessData.accessCode
                ? "Generating your Access Code..."
                : "Your Access Code:"}
            </h4>
            {accessData.accessCode && (
              <>
                <h1>{accessData.accessCode}</h1>
                <Muted>
                  Your Access Code has been sent to your email and mobile phone.
                  You need it to access this event.
                </Muted>
              </>
            )}
          </GridItem>
          {accessData.accessCode && (
            <GridItem xs={12} sm={12} md={12} className={classes.buttonAlign}>
              <Button
                color="success"
                className={buttonClassname}
                // onClick={handlePaymentForm}
                href={`/event/` + accessData.metadata.event_slug}
              >
                Watch Now
              </Button>
            </GridItem>
          )}
        </GridContainer>
      </>
    );
  }
}
