import React, { useState, useEffect } from "react";
import API from "utils/API";
import ReactJWPlayer from "react-jw-player";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/Custom/Stripe/CheckoutForm";
// core components
import Header from "components/Header/Header.js";
// import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import Parallax from "components/Parallax/Parallax.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import artistStyles from "assets/jss/material-kit-react/views/landingPage.js";
// import { cardTitle } from "assets/jss/material-kit-react.js";
import { useRouteMatch } from "react-router";
import Countdown from "react-countdown";
import Primary from "components/Typography/Primary.js";
import Danger from "components/Typography/Danger.js";

const useStyles = makeStyles(theme => ({
  ...artistStyles,
  main: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10vh"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "20vh"
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "25vh"
    }
  },
  player: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingTop: "25vh"
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "20vh"
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "15vh"
    }
  },
  eventTitle: {
    color: "#FFFFFF",
    margin: "1.75rem 0 0.875rem",
    textDecoration: "none",
    fontWeight: "700",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    backgroundColor: "rgba(52, 52, 52, 0.4)",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "700",
      fontSize: "18px"
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: "700"
    },
    [theme.breakpoints.up("lg")]: {
      fontWeight: "700"
    }
  },
  description: {
    backgroundColor: "rgba(52, 52, 52, 0.4)",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  cardCustom: {
    // height: "100%",
    justify: "center",
    [theme.breakpoints.down("xs")]: {
      width: "18rem",
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down("sm")]: {
      width: "18rem",
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up("md")]: {
      width: "20rem",
      padding: theme.spacing(0)
    },
    [theme.breakpoints.up("lg")]: {
      width: "22rem",
      padding: theme.spacing(1)
    }
  }
}));

export default function EventPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [initialized, setInitialized] = useState(false);
  const [paymentForm, setPaymentForm] = useState(false);
  const [logOut, setLogOut] = useState(false);

  let route = useRouteMatch("/event/:id");
  // let accessCode = window.localStorage.getItem(eventData.eventId);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const [eventData, setEventData] = useState("");

  const getEvent = async eventSlug => {
    API.get(`/events/${eventSlug}`)
      .then(response => {
        console.log("DATA:", response.data);
        setEventData(response.data[0]);
        return response.data[0];
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {});
  };

  let accessCode = window.localStorage.getItem(eventData.eventId);

  const Checkout = () => {
    return (
      <Elements stripe={stripePromise}>
        <CheckoutForm
          intent={paymentIntent}
          visible={handlePaymentForm}
          route={route}
        />
      </Elements>
    );
  };

  /**
   * PlayVideo Component
   * @param {*} playerProps
   */

  const [accessData, setAccessData] = useState("");
  const [getAccessError, setGetAccessError] = useState("");

  const PlayVideo = playerProps => {
    // console.log("ACCESS DATA", accessData);
    return (
      <div className={playerProps.class} style={{ height: "100%" }}>
        {playerProps.preview && (
          <>
            <h6 className={classes.cardTitle}>Your Event is Coming Up...</h6>
          </>
        )}
        <ReactJWPlayer
          preview={playerProps.preview}
          playerId={playerProps.playerId}
          playerScript={playerProps.playerScript}
          file={playerProps.file}
          isMuted={playerProps.isMuted}
          isAutoPlay={playerProps.isAutoPlay}
          customProps={{
            ga: {
              label: "title"
            }
          }}
        />
        {playerProps.preview && (
          <Countdown date={eventData.startTime} renderer={renderer} />
        )}
        <Clearfix />
      </div>
    );
  };

  const handlePaymentForm = (e, visible) => {
    e.preventDefault();
    if (visible) getPaymentIntent(eventData.eventId);
    setPaymentForm(visible);
  };

  // Random component
  const Completionist = () => (
    <Button color="primary" href={`/event/` + eventData.eventSlug}>
      Watch Now{" "}
      <i className="fas fa-play" style={{ fontSize: 12, padding: "5px" }} />
    </Button>
  );

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <Primary>
          <strong>Starts in: </strong>
          {days} D : {hours} H : {minutes} M : {seconds} S
        </Primary>
      );
    }
  };

  const EventInfo = () => {
    // console.log("EVENT DATA:", eventData);
    const showEvent =
      accessData.accessCode && eventData.startTime <= Date.now() ? false : true;
    if (!showEvent) {
      return null;
    }

    if (eventData.free === true) return null;

    return (
      <div className={classes.main} style={{ height: "100%" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} data-background-color="orange">
            <h1 className={classes.eventTitle}>{eventData.title}</h1>
            <h4 className={classes.description}>{eventData.description}</h4>
            <br />
            {/* {eventData.free === true && <h1>IT IS FREE</h1>} */}
            {!paymentForm && !accessData.accessCode && (
              <Button
                color="success"
                size="lg"
                disabled={paymentForm}
                onClick={e => handlePaymentForm(e, true)}
              >
                Buy Ticket - {(eventData.price / 100).toFixed(2)}{" "}
                {eventData.currency}
              </Button>
            )}
          </GridItem>
          {paymentForm && (
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardBody>
                  <Checkout />
                </CardBody>
              </Card>
            </GridItem>
          )}
          {!paymentForm && (
            <GridItem xs={12} sm={12} md={6}>
              <Card className={classes.cardCustom}>
                {accessData && eventData.startTime >= Date.now() && (
                  <CardHeader color="danger">Watch a Preview</CardHeader>
                )}
                <CardBody>
                  {accessData.accessCode &&
                    eventData.startTime >= Date.now() && (
                      <PlayVideo
                        preview={true}
                        playerId={eventData.eventId || ""}
                        playerScript={accessData.playerScript || ""}
                        file={accessData.file || ""}
                        isMuted={false}
                        isAutoPlay={false}
                        gaLabel={accessData.title}
                      />
                    )}
                  {!accessData.accessCode && (
                    <>
                      <h4 className={classes.cardTitle}>I Have a Ticket</h4>
                      <CustomInput
                        id="access_code"
                        inputProps={{
                          placeholder: "Access Code B-XXXXXX"
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                      <p>
                        Enter your access code begining with <strong>B-</strong>{" "}
                        and click on Watch Now.
                      </p>
                      <Button
                        color="primary"
                        className={classes.textRight}
                        onClick={() => {
                          getAccess(
                            document.getElementById("access_code").value,
                            eventData.eventId
                          );
                        }}
                      >
                        Watch Now{" "}
                        <i
                          className="fas fa-play"
                          style={{ fontSize: 12, padding: "5px" }}
                        />
                      </Button>
                      {getAccessError && <Danger>{getAccessError}</Danger>}
                    </>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  };

  /**
   * Get getPaymentIntent
   */
  const [paymentIntent, setPaymentIntent] = useState("");
  const getPaymentIntent = eventId => {
    API.get(`/payment/secret/${eventId}`)
      .then(response => {
        // console.log("DATA:", response.data);
        setPaymentIntent(response.data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {});
  };

  /**
   * Get accessCode and set states
   * @param {*} accessCode
   */
  const getAccess = (accessCode, eventId) => {
    if (!accessCode) {
      setGetAccessError("Access code is empty");
      return null;
    }

    if (!eventId) {
      setGetAccessError("Event id is empty");
      return null;
    }

    API.post(`/events/play/`, {
      accessCode: accessCode,
      eventId: eventId
    })
      .then(response => {
        // console.log("DATA:", response.data);
        setInitialized(true);
        setLogOut(true);
        setAccessData(response.data);
        if (response.data) {
          window.localStorage.setItem(
            // "accessCode_" + route.params.id, //eventData.eventId
            eventData.eventId,
            accessCode
          );
        }
      })
      .catch(error => {
        setGetAccessError(
          error.response.data + " (" + error.response.status + ")"
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    getEvent(route.params.id);
    if (!initialized) {
      if (accessCode) {
        getAccess(accessCode, eventData.eventId);
      }
    }
  }, [accessCode]);

  return (
    <div>
      {eventData && (
        <>
          <Header
            absolute
            color="transparent"
            brand="BeebopCafé TV"
            rightLinks={
              <HeaderLinks
                localStorageKey={eventData.eventId}
                showLogOut={logOut}
              />
            }
            {...rest}
          />
          <div
            className={classes.pageHeader}
            style={{
              // paddingTop: "20vh",
              backgroundImage:
                "url(" +
                eventData.image +
                `?fit=crop&crop=entropy&w=1920&fm=jpg&lossless=1&q=80` +
                ")",
              backgroundSize: "cover",
              backgroundPosition: "top center",
              height: "100vh"
            }}
          >
            <div
              className={classes.container}
              id="player"
              style={{ height: "100%" }}
            >
              {" "}
              {accessData.accessCode &&
                eventData.startTime <= Date.now() &&
                !eventData.free && (
                  <PlayVideo
                    preview={false}
                    playerId={accessData.playerId || ""}
                    playerScript={accessData.playerScript || ""}
                    file={accessData.file || ""}
                    isMuted={false}
                    isAutoPlay={true}
                    class={classes.player}
                    gaLabel={accessData.title}
                  />
                )}
              {eventData.free === true && (
                <GridContainer>
                  <GridItem>
                    <PlayVideo
                      preview={false}
                      playerId={eventData.eventId || ""}
                      playerScript="https://cdn.jwplayer.com/libraries/EJtHZxzI.js"
                      file={eventData.preview || ""}
                      isMuted={false}
                      isAutoPlay={true}
                      class={classes.player}
                      gaLabel={eventData.title}
                    />
                  </GridItem>
                  <GridItem>
                    <h1 className={classes.eventTitle}>{eventData.title}</h1>
                    <h4 className={classes.description}>
                      {eventData.description}
                    </h4>
                  </GridItem>
                </GridContainer>
              )}
              <EventInfo />
              {/* <Footer /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
