/*eslint-disable*/
import React, { useState, useEffect } from "react";
import API from "utils/API";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  // const [logOut, setLogOut] = useState(false);

  // useEffect(() => {
  //   if (window.localStorage.getItem(props.localStorageKey)) {
  //     setLogOut(true);
  //   }
  // })

  const handleLogout = () => {
    API.delete(`/access/code/${localStorage.getItem(props.localStorageKey)}`)
    .then(response => {
      // setLogOut(false);
      localStorage.removeItem(props.localStorageKey);
    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => {
      window.location.reload(true);
    });
  }
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
  <Link to={`/`} className={ classes.navLink }>Home</Link>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
      <Button
            color="transparent"
            href="https://live.beebopcafe.tv/"
            target="_blank"
            className={classes.navLink}
          >
            20/20 VISION
          </Button>
      </ListItem>                 */}
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/beebopcafe"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      {props.showLogOut && (
        <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Log out of this event"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
        <Button
              color="transparent"
              className={classes.navLink}
              onClick={handleLogout}
            >
              Log Out
            </Button>
            </Tooltip>          
        </ListItem>   
      )}          
    </List>
  );
}
