import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Favorite from "@material-ui/icons/Favorite";
// import Button from "components/CustomButtons/Button.js";
// import Countdown from "react-countdown";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import ReactJWPlayer from "react-jw-player";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Helmet } from "react-helmet";

// Sections for this page
// import ProductSection from "./Sections/ProductSection.js";
// import TeamSection from "./Sections/TeamSection.js";
// import WorkSection from "./Sections/WorkSection.js";
import EventsSection from "./Sections/EventsSection.js";

const dashboardRoutes = [];

// const useStyles = makeStyles(styles);

const useStyles = makeStyles(theme => ({
  ...styles,
  // ...styles.title,
  title: {
    ...styles.title,
    display: "inline-block",
    position: "relative",
    color: "#FFFFFF",
    textDecoration: "none",
    justify: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
      minHeight: "32px",
      fontSize: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      minHeight: "32px",
      fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
      minHeight: "32px",
      fontSize: "52px"
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "30px",
      minHeight: "32px",
      fontSize: "52px"
    }
  },
  description: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  }
}));

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="BeebopCafé TV"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "dark"
        }}
        {...rest}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <title>BeebopCafé TV | Live Art Entertainment</title>
        <meta
          name="description"
          content="BeebopCafé TV (BееBCTV) - watch live concerts, theater and 24/7 TV recorded live at the Beebop Café. #ArtEQ"
        />
        <meta
          property="og:title"
          content="BeebopCafé TV | Live Art Entertainment"
        />
        <meta
          property="og:description"
          content="BeebopCafé TV (BееBCTV) - watch live concerts, theater and 24/7 TV recorded live at the Beebop Café. #ArtEQ"
        />
        <meta property="og:site_name" content="BeebopCafé TV" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://beebopcafe.tv" />
        <meta
          property="og:image"
          content="https://beebopcafetv.imgix.net/images/beebop-cafe-bg.jpg?fit=crop&w=1200&h=630&fm=jpg&lossless=1&q=80"
        />
        <link rel="canonical" href="https://beebopcafe.tv" />
      </Helmet>
      <Parallax
        filter
        image={
          "https://beebopcafetv-resources.imgix.net/images/beebop-cafe-bg.jpg?fit=crop&max-w=1920&fm=jpg&lossless=1&q=80"
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Live Art Entertainment.</h1>
              <h4 className={classes.description}>
                Beebop Café TV - watch live concerts, theater and 24/7 TV
                recorded live at the Beebop Café. #ArtEQ
              </h4>
              <br />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <ReactJWPlayer
                playerId="home"
                playerScript="https://cdn.jwplayer.com/libraries/yPcrAFVA.js"
                // file="https://beebop.prod.ioio.tv/broker/play/3acaca03-eb7f-4825-8d27-1bdecdd9ed11.m3u8"
                file="https://beebop.prod.ioio.tv/broker/play/23ca72b8-f10a-4e49-8281-cf65b8c511f9.m3u8"
                isMuted={false}
                isAutoPlay={true}
                customProps={{
                  ga: {
                    label: "Linear TV"
                  }
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {/* <a href="https://live.beebopcafe.tv/">
                    <Button color="primary" round>
                      <Favorite /> 20/20 VISION | Jan 10, 7PM EEST | FREE
                      ADDMISSION
                    </Button>
                  </a> */}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.mainRaised)}>
        <div className={classes.container}>
          <EventsSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
