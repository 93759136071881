import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import ReactJWPlayer from "react-jw-player";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Beebop Café TV"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container} style={{ paddingTop: "7%" }}>
          <h2>Blues Traffic - Тя каза не - Nylon Special</h2>
          <ReactJWPlayer
            // preview={playerProps.preview}
            playerId="bluestraffic"
            playerScript="https://cdn.jwplayer.com/libraries/EJtHZxzI.js"
            file="https://beebop.prod.ioio.tv/channels/eu-west-1/68277919-4b99-42a3-a0b5-c3c179834d02/master0.m3u8"
            image="https://beebopcafetv-resources.imgix.net/images/blues-traffic-poster.jpg?w=1200"
            isMuted={false}
            isAutoPlay={false}
          />
          {/* <img
            style={{ width: "100%" }}
            src="https://beebopcafetv-resources.imgix.net/images/blues-traffic-poster.jpg?w=1200"
            alt="Blues Traffic - Тя каза не"
            title="Blues Traffic - Тя каза не"
          ></img> */}
          <p>
          Blues Traffic - Тя каза не - Nylon Special
          </p>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
