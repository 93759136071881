import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import axios from "axios";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Link } from "react-router-dom";
import Timestamp from "react-timestamp";
import Primary from "components/Typography/Primary.js";
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";
import Badge from "components/Badge/Badge.js";

import { cardTitle } from "assets/jss/material-kit-react.js";

import homePageStyles from "assets/jss/material-kit-react/views/homePage.js";
// import SectionCountdown from "../../views/Components/Sections/SectionCountdown";
import Countdown from "react-countdown";

// import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
const useStyles = makeStyles(theme => ({
  ...homePageStyles,
  ...imagesStyles,
  cardTitle,
  cardCustom: {
    // height: "100%",
    justify: "center",
    [theme.breakpoints.down("xs")]: {
      width: "18rem",
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down("sm")]: {
      width: "18rem",
      padding: theme.spacing(1)
    },
    [theme.breakpoints.up("md")]: {
      width: "20rem",
      padding: theme.spacing(0)
    },
    [theme.breakpoints.up("lg")]: {
      width: "22rem",
      padding: theme.spacing(1)
    }
  }
}));

export default function EventsSection() {
  const classes = useStyles();

  // const [playPreview, setPlayPreview] = useState();

  // const handlePreview = () => {
  //   setPlayPreview(true);
  // };

  const ShowEvents = () => {
    const [events, setEvents] = useState([]);
    const sortedEvents = events.sort(function(a, b) {
      return b.startTime - a.startTime;
    });
    useEffect(() => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      const getEvents = () => {
        try {
          axios
            .get(process.env.REACT_APP_BASE_URL + `events/`, {
              cancelToken: source.token
            })
            .then(response => {
              setEvents(response.data);
              // console.log("DATA:", response.data);
            });
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("cancelled");
          } else {
            throw error;
          }
        }
      };

      getEvents();
      return () => {
        source.cancel();
      };
    }, []);

    // Random component
    const Completionist = () => <Badge color="rose">LIVE</Badge>;

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Completionist />;
      } else {
        // Render a countdown
        return (
          <Primary>
            <strong>Starts in: </strong>
            {days} D : {hours} H : {minutes} M : {seconds} S
          </Primary>
        );
      }
    };

    return (
      <>
        {sortedEvents.map(event => (
          <GridItem xs={12} sm={6} md={4} lg={4} key={event.startTime}>
            <Card className={classes.cardCustom}>
              <CardHeader color="warning">
                {event.endTime >= Date.now() && (
                  <h6>
                    <Timestamp
                      date={event.startTime / 1000}
                      options={{ includeDay: true, twentyFourHour: true }}
                    />
                  </h6>
                )}
                {event.endTime <= Date.now() && <h6>Watch Again</h6>}
              </CardHeader>
              <Link to={`/event/` + event.eventSlug}>
                <div>
                  <Icon
                    style={{
                      position: "absolute",
                      color: "rgb(252, 147, 13)",
                      top: "20%",
                      left: "40%",
                      fontSize: "62px"
                    }}
                  >
                    play_circle_outline
                  </Icon>
                  <img
                    // onClick={handlePreview}
                    style={{
                      height: "180px",
                      width: "100%",
                      display: "block"
                    }}
                    className={classes.imgCardTop}
                    src={
                      event.image +
                      `?fit=crop&w=320&h=180&fm=jpg&lossless=1&q=80`
                    }
                    alt={event.title}
                  />
                </div>
              </Link>
              <CardBody>
                {event.endTime >= Date.now() && (
                  <Countdown date={event.startTime} renderer={renderer} />
                )}
                <h4 className={classes.cardTitle}>
                  <Link to={`/event/` + event.eventSlug}>{event.title}</Link>
                </h4>
                <p>{event.description}</p>
                {event.free === true && (
                  <Button color="primary" href={`/event/` + event.eventSlug}>
                    FREE - WATCH NOW
                  </Button>
                )}
                {!event.free && (
                  <Button color="primary" href={`/event/` + event.eventSlug}>
                    Buy Ticket - {event.price / 100} {event.currency}
                  </Button>
                )}
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </>
    );
  };

  return (
    <GridContainer justify="center">
      <ShowEvents />
    </GridContainer>
  );
}
