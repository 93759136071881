import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
// import Components from "views/Components/Components.js";
// import HomePage from "views/HomePage/HomePage.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import EventPage from "views/EventPage/EventPage.js";
// import LoginPage from "views/LoginPage/LoginPage.js";
import PlayerPage from "views/PlayerPage/PlayerPage.js";
import PrivacyPolicyPage from "views/PrivacyPolicyPage/PrivacyPolicyPage";
import ProfilePage from "views/ProfilePage/ProfilePage";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/event/:id" component={EventPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      {/* <Route path="/login-page" component={LoginPage} /> */}
      <Route path="/live" component={PlayerPage} />
      <Route path="/profile-page" component={ProfilePage} />
      {/* <Route path="/" component={HomePage} /> */}
      <Route path="/" component={LandingPage} />
      {/* <Route path="/" component={Components} /> */}
    </Switch>
  </Router>,
  document.getElementById("root")
);
